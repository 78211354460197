<script setup lang="ts">
    import { ref, computed, onMounted } from 'vue'
    import { storeToRefs } from 'pinia'
    import { useRouter } from 'vue-router'
    import { useAuthStore } from '@stores/authStore'
    import { useCommunicationStore } from '@stores/communicationStore'
    import { useCalendarStore } from '@stores/calendarStore'
    import { useTaskStore } from '@stores/taskStore'
    import { useOrganisationStore } from '@stores/organisationStore'
    import { useNotificationStore } from '@/stores/notificationStore'
    import { apiResetCollaboration, apiCollaborate } from '@/api/organisation'

    import { ctrlShift1, ctrlShift2 } from '@/utilities/shortcuts/shortCutMap'
    import HintWidget from '@/utilities/shortcuts/hintWidget.vue'
    import { useHintWidget } from '@/utilities/shortcuts/hintWidget'
    import MainMenuLanguage from '@/components/MainMenuLanguage.vue'

    const { neitherDialogDrawerCtrlIsPressed } = useHintWidget()
    ctrlShift1(() => router.replace({ name: 'strategy' }), 'neither')
    ctrlShift2(() => router.replace({ name: 'calendar' }), 'neither')

    // authentication
    import { useAuthorisation } from '@/composables/authorisation'

    import { useI18n } from 'vue-i18n'
    const { t } = useI18n()

    import { useScreenWidth } from '@/utilities/useScreenWidth'
    const { isMobile } = useScreenWidth()

    // const route = useRoute()
    const router = useRouter()
    const authStore = useAuthStore()
    const commStore = useCommunicationStore()
    const calStore = useCalendarStore()
    const taskStore = useTaskStore()
    const orgStore = useOrganisationStore()
    const notStore = useNotificationStore()

    const { notifications } = storeToRefs(notStore)

    const webrole = authStore.me.webrole_id || 5
    const notReadonly = ref(webrole !== 5)
    const collaborationUser = authStore.me.isCollaborationUser || false
    const departmentUser = authStore.me.isDepartmentUser || false
    const canProcessRequests = authStore.me.procesRequests || false
    const notSubscribe = authStore.subscription === 'Trial' || authStore.subscription === 'Solo' ? true : false

    const validReadonly = computed(() => collaborationUser == false)

    const { userFlag, canRead } = useAuthorisation()
    const editSettings = userFlag.value('canEditSettings')
    const handleRequests = computed(() => {
        return canRead.value('request') && hasFlag('departmentsFunctionality') && canProcessRequests ? true : false
    })

    // authorisation
    import { type PermissionFlag } from '@/api/auth'
    import { onUnmounted } from 'vue'

    function hasFlag(flag: PermissionFlag) {
        if (authStore.permissionFlags === undefined) return false
        return authStore.permissionFlags[flag] == true
    }

    const isDev = import.meta.env.VITE_ENVIRONMENT === 'dev'
    const isAcc = import.meta.env.VITE_ENVIRONMENT === 'acc'

    // User submenu
    const userMenu = ref(false)
    const userItems = ref([
        {
            text: 'user.myProfile',
            icon: 'far fa-user',
            routeName: 'profile',
            active: true,
        },
        {
            text: 'user.logout',
            icon: 'far fa-right-from-bracket',
            routeName: 'logout',
            active: true,
        },
    ])

    // Settings submenu
    const adminMenu = ref(false)
    const impersonateMenu = ref(false)
    const adminItems = ref()

    onMounted(() => {
        adminItems.value = [
            {
                text: 'generalKeys.organizationData',
                icon: 'far fa-building',
                routeName: 'account',
                active: true,
            },
            {
                text: 'generalKeys.calendars',
                icon: 'far fa-calendar-lines',
                routeName: 'calendars',
                active: hasFlag('departmentsFunctionality'),
            },
            {
                text: 'generalKeys.users',
                icon: 'far fa-users',
                routeName: 'users',
                active: true,
            },
            {
                text: 'generalKeys.partnerSetting',
                icon: 'fas fa-arrow-right-arrow-left',
                routeName: 'partners',
                active: false,
            },
            {
                text: 'generalKeys.workflows',
                icon: 'fas fa-list-check',
                routeName: 'workflows',
                active: hasFlag('canUseWorkflows'),
            },
            {
                text: 'generalKeys.holidays',
                icon: 'far fa-bookmark',
                routeName: 'holidays',
                active: true,
            },
            {
                text: 'generalKeys.hooks',
                icon: 'fas fa-sparkle',
                routeName: 'specialDays',
                active: false,
            },
        ]
    })

    // Dev submenu
    const devMenu = ref(false)
    const devItems = ref([
        {
            text: 'Verversen',
            icon: 'far fa-arrows-rotate',
            routeName: 'refresh',
            active: true,
        },
        {
            text: 'Checks',
            icon: 'far fa-check',
            routeName: 'dev',
            active: true,
        },
        {
            text: 'Authorisation',
            icon: 'far fa-user',
            routeName: 'authtest',
            active: true,
        },
    ])

    // refresh store every 5 minutes
    let intervalId: any = null
    onMounted(() => {
        intervalId = setInterval(refreshStore, 1000 * 60 * 15)
    })
    onUnmounted(() => {
        clearInterval(intervalId)
    })

    function refreshStore() {
        authStore.refreshStore()
        taskStore.refreshStore()
        calStore.refreshStore()
        commStore.refreshStore()
        orgStore.refreshStore()
        console.log('Store refreshed...')
    }

    function logout() {
        authStore.logout()
        authStore.localStorageClear()
        commStore.resetStore()
        calStore.resetStore()
        taskStore.resetStore()
        sessionStorage.clear()
    }

    function goto(name: string) {
        router.replace({ name })
    }
    function handleClick(action: string) {
        switch (action) {
            case 'refresh':
                refreshStore()
                break
            case 'logout':
                logout()
                break
            default:
                goto(action)
        }

        if (action === 'logout') {
            logout()
        }
    }

    const colorContrast = computed(() => orgStore.organisation.colorContrast || '#2B5062')
    const colorCode = computed(() => orgStore.organisation.colorCode || '#ffffff')

    const isDark = ref(false)

    const logoShadow = computed(() => {
        const hex = orgStore?.organisation?.colorContrast?.replace(/^#/, '')
        const bigint = parseInt(hex as string, 16)
        const r = (bigint >> 16) & 255
        const g = (bigint >> 8) & 255
        const b = bigint & 255

        // Calculate luminance
        const luminance = 0.299 * r + 0.587 * g + 0.114 * b

        // Set a threshold (you can adjust this value)
        const threshold = 128

        isDark.value = luminance < threshold

        if (isDark.value) {
            return 'light-shadow'
        } else {
            return 'dark-shadow'
        }
    })

    const notificationCounter = computed(() => {
        let count = 0
        if (notifications.value) {
            notifications.value.forEach((notification) => {
                if (notification.readNotification === false || notification.readNotification === null) {
                    count++
                }
            })
        }
        return count
    })

    async function emptyLocalStorageFilter() {
        localStorage.removeItem('filter-projecten')
        localStorage.removeItem('filter-gebruiker')
        localStorage.removeItem('filter-content type')
        localStorage.removeItem('filter-media channel')
        localStorage.removeItem('filter-target')
        localStorage.removeItem('filter-audience')
        localStorage.removeItem('filter-label')
        return true
    }

    async function startCollaborate(id: number, name: string) {
        const title = t('impersonate.to', { name })
        const res = await apiCollaborate(id, title)
        if (res) {
            // clear local storage containing the word filter
            await emptyLocalStorageFilter()
            router.replace({ name: 'calendar' })
        }
    }

    async function endCollaborate() {
        await emptyLocalStorageFilter()
        apiResetCollaboration(t('impersonate.back'))
    }

    function handleNotificationShow() {
        if (!authStore.notificationPanel.show && authStore.notificationPanel.navBtn) {
            authStore.notificationPanel.show = true
        } else if (authStore.notificationPanel.show && !authStore.notificationPanel.navBtn) {
            authStore.notificationPanel.show = false
        }
    }
</script>

<template>
    <v-navigation-drawer theme="dark" :color="colorContrast" rail permanent class="py-0 d-print-none">
        <v-img :width="40" aspect-ratio="16/9" cover src="/logo_menu.png" :class="logoShadow" style="opacity: 1">
            <v-tooltip activator="parent" theme="light" location="end">Contentkalender.nl</v-tooltip>
        </v-img>
        <v-divider></v-divider>

        <!-- Main menu -->
        <!-- FIXME - CK3-284-authentication-check Added condition for ReadOnly roll -->
        <v-list density="compact" nav v-if="notReadonly">
            <!-- - - - - - STRATEGY -->
            <template v-if="!isMobile">
                <v-list-item
                    prepend-icon="far fa-flag"
                    title="Strategy"
                    v-if="!neitherDialogDrawerCtrlIsPressed"
                    value="strategy"
                    :style="{ color: colorCode }"
                    @click="router.replace({ name: 'strategyName', params: { name: 'project' } })"
                >
                    <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.contentStrategy') }}</v-tooltip>
                </v-list-item>
                <v-list-item v-else>
                    <template #prepend>
                        <hint-widget shift-key :key-press="'1'" />
                    </template>
                </v-list-item>
            </template>

            <!-- - - - - - REQUEST -->

            <template v-if="handleRequests && !isMobile">
                <!-- beheerder or campaign manager-->
                <v-list-item
                    prepend-icon="far fa-calendar-plus"
                    title="Taken"
                    v-if="!neitherDialogDrawerCtrlIsPressed"
                    value="request"
                    :style="{ color: colorCode }"
                    @click="router.replace({ name: 'request' })"
                >
                    <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.request') }}</v-tooltip>
                </v-list-item>
                <v-list-item v-else>
                    <template #prepend>
                        <hint-widget shift-key :key-press="'2'" />
                    </template>
                </v-list-item>
            </template>
            <!-- - - - - - COMMUNICATIONS -->
            <v-list-item
                prepend-icon="far fa-calendar"
                title="Communicaties"
                v-if="!neitherDialogDrawerCtrlIsPressed"
                value="communications"
                :style="{ color: colorCode }"
                @click="router.replace({ name: 'calendar', params: { period: 'month' } })"
            >
                <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.communications') }}</v-tooltip>
            </v-list-item>
            <v-list-item v-else>
                <template #prepend>
                    <hint-widget shift-key :key-press="'3'" />
                </template>
            </v-list-item>

            <!-- - - - - - TASKS -->
            <v-list-item
                prepend-icon="far fa-square-check"
                title="Taken"
                v-if="!neitherDialogDrawerCtrlIsPressed"
                value="tasks"
                :style="{ color: colorCode }"
                @click="router.replace({ name: 'tasksCalendar', params: { period: 'month' } })"
            >
                <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.tasks') }}</v-tooltip>
            </v-list-item>
            <v-list-item v-else>
                <template #prepend>
                    <hint-widget shift-key :key-press="'4'" />
                </template>
            </v-list-item>
        </v-list>
        <v-list v-else density="compact" nav>
            <v-list-item
                prepend-icon="far fa-calendar"
                title="Communicaties"
                v-if="!neitherDialogDrawerCtrlIsPressed"
                value="communications"
                :style="{ color: colorCode }"
                @click="router.replace({ name: 'calendar', params: { period: 'month' } })"
            >
                <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.communications') }}</v-tooltip>
            </v-list-item>
            <v-list-item v-else>
                <template #prepend>
                    <hint-widget shift-key :key-press="'2'" />
                </template>
            </v-list-item>
        </v-list>

        <!-- Secondary menu -->
        <template v-slot:append>
            <v-list density="compact" nav class="pl-2">
                <v-list-item v-if="notSubscribe" :title="t('generalKeys.upgrade')" value="subscription" :style="{ color: colorCode }" @click="router.push({ name: 'subscription' })">
                    <template v-slot:prepend>
                        <v-icon size="26px">fas fa-circle-up</v-icon>
                    </template>
                    <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.upgrade') }}</v-tooltip>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <!-- FIXME - CK3-284-authentication-check Added condition for ReadOnly roll -->
            <template v-if="notReadonly && !isMobile">
                <v-list density="compact" nav>
                    <v-list-item prepend-icon="far fa-bell" :title="t('generalKeys.notifications')" value="notifications" :style="{ color: colorCode }" @click="handleNotificationShow">
                        <v-list-item-subtitle class="notificationCounter" v-if="notificationCounter > 0">
                            <b>{{ notificationCounter }}</b>
                        </v-list-item-subtitle>
                        <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.notifications') }}</v-tooltip>
                    </v-list-item>

                    <v-menu v-model="impersonateMenu" v-if="!authStore.impersonation" :close-on-content-click="true" location="end">
                        <!-- FIXME - CK3-284-authentication-check Added condition for Compagne manager and Redacteur roll -->

                        <template v-if="orgStore.toCollaborations.length" v-slot:activator="{ props }">
                            <v-list-item v-bind="props" prepend-icon="far fa-shuffle" title="Impersonation" value="impersonate" :style="{ color: colorCode }">
                                <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.impersonate') }}</v-tooltip>
                            </v-list-item>
                        </template>
                        <v-card min-width="300">
                            <v-list>
                                <template v-for="collab in orgStore.toCollaborations">
                                    <v-list-item prepend-icon="far fa-shuffle" :value="collab" color="primary" @click="startCollaborate(collab.id, collab.to_organisation_name)">
                                        <v-list-item-title v-text="collab.to_organisation_name"></v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card>
                    </v-menu>
                    <v-list-item v-else prepend-icon="fa-kit fa-solid-shuffle-circle-xmark" title="impersonation" value="impersonate" @click="endCollaborate()" :style="{ color: colorCode }">
                        <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.impersonateReturn') }}</v-tooltip>
                    </v-list-item>

                    <!-- Admin sub menu -->
                    <v-menu v-model="adminMenu" :close-on-content-click="true" location="end">
                        <!-- FIXME - CK3-284-authentication-check Added condition for Compagne manager and Redacteur roll -->

                        <template v-slot:activator="{ props }" v-if="editSettings">
                            <v-list-item v-bind="props" prepend-icon="far fa-cog" title="Beheer" value="admin" :style="{ color: colorCode }">
                                <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.setting') }}</v-tooltip>
                            </v-list-item>
                        </template>
                        <v-card min-width="300">
                            <v-list>
                                <template v-for="item in adminItems">
                                    <v-list-item v-if="item.active" :value="item" color="primary" @click="handleClick(item.routeName)">
                                        <template v-slot:prepend>
                                            <v-icon :icon="item.icon" size="small"></v-icon>
                                        </template>
                                        <v-list-item-title v-text="t(item.text)"></v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card>
                    </v-menu>

                    <!-- User sub menu -->
                    <template v-if="collaborationUser == false">
                        <v-menu v-model="userMenu" :close-on-content-click="true" location="end">
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props" prepend-icon="far fa-user" title="Beheer" value="beheer" :style="{ color: colorCode }">
                                    <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.userSetting') }}</v-tooltip>
                                </v-list-item>
                            </template>
                            <v-card min-width="300">
                                <v-list>
                                    <v-list-item v-for="(item, i) in userItems" :key="i" :value="item" color="primary" @click="handleClick(item.routeName)">
                                        <template v-slot:prepend>
                                            <v-icon :icon="item.icon" size="small"></v-icon>
                                        </template>
                                        <v-list-item-title v-text="t(item.text)"></v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </template>

                    <template v-if="isDev || isAcc">
                        <!-- User developer menu -->
                        <v-menu v-model="devMenu" :close-on-content-click="true" location="end">
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props" prepend-icon="far fa-wrench" title="Beheer" :style="{ color: colorCode }" value="beheer">
                                    <v-tooltip activator="parent" theme="light" location="end">! Development menu !</v-tooltip>
                                </v-list-item>
                            </template>
                            <v-card min-width="300">
                                <v-list>
                                    <v-list-item v-for="(item, i) in devItems" :key="i" :value="item" color="primary" @click="handleClick(item.routeName)">
                                        <template v-slot:prepend>
                                            <v-icon :icon="item.icon" size="small"></v-icon>
                                        </template>
                                        <v-list-item-title v-text="item.text"></v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </template>
                </v-list>
            </template>
            <template v-else>
                <!-- Readonly stop impersonalisation -->
                <v-list-item
                    v-if="authStore.impersonation"
                    prepend-icon="fa-kit fa-solid-shuffle-circle-xmark"
                    title="impersonation"
                    value="impersonate"
                    @click="apiResetCollaboration(t('impersonate.back'))"
                    :style="{ color: colorCode }"
                >
                    <v-tooltip activator="parent" theme="light" location="end">{{ t('generalKeys.impersonateReturn') }}</v-tooltip>
                </v-list-item>

                <!-- Readonly profile -->
                <v-list v-if="validReadonly" density="compact" nav>
                    <v-list-item v-if="!departmentUser" prepend-icon="far fa-user" title="Profile" value="notifications" :style="{ color: colorCode }" @click="goto('profile')">
                        <v-tooltip activator="parent" theme="light" location="end">{{ t('user.myProfile') }}</v-tooltip>
                    </v-list-item>

                    <MainMenuLanguage />

                    <v-list-item prepend-icon="far fa-right-from-bracket" title="Notificaties" value="notifications" :style="{ color: colorCode }" @click="logout()">
                        <v-tooltip activator="parent" theme="light" location="end">{{ t('user.logout') }}</v-tooltip>
                    </v-list-item>
                </v-list>
            </template>
        </template>
    </v-navigation-drawer>
</template>

<style lang="scss" scoped>
    .position {
        position: absolute;
        top: 10%;
        left: 50%;
    }

    .light-shadow {
        margin: 1.5rem auto;
    }

    .dark-shadow {
        margin: 1.5rem auto;
        filter: drop-shadow(1px 1px 1px black);
    }

    .notificationCounter {
        position: absolute;
        right: -2px;
        top: 0;
        color: white;
        border: 1px solid red;
        border-radius: 40%;
        min-width: 1.1rem;
        width: fit-content;
        height: 1.1rem;
        background-color: red;
        text-align: center;
    }

    :deep(.subscription-tag .v-list-item__content) {
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .subscription-tag {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
